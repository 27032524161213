
<template>
  <v-container fluid>
    <v-row justify="center" no-gutters class="mt-3 pb-4 scheduleSelection">
      <div class="d-flex bordered qp-tab-nav">
      <div class="text-lg-right pr-0" style="width: 12rem !important">
        <v-select
            v-if="checkReadPermission($modules.facility.schedule.slug)"
            class="no-right-border"
            :items="venueServices"
            v-model="venueService"
            item-value="venue_service_id"
            item-text="name"
            outlined
            :height="46"
            :menu-props="{ bottom: true, offsetY: true }"
            return-object
            readonly
            background-color="white"
            hide-details
            label="Service"
            dense
            flat
        ></v-select>
      </div>
        <ScheduleTabs  :approvals-class="true" :venue_service-id="venueService.venue_service_id"  />
      </div>
<!--      <div class="pl-0">-->
<!--        <v-btn-->
<!--            v-if="checkReadPermission($modules.facility.approval.slug)"-->
<!--            @click="gotoSchedule"-->
<!--            class="elevation-0 no-border-radius"-->
<!--            style="height: 40px; border: 1px solid #dcdcdc !important"-->
<!--            color="white"-->
<!--        >-->
<!--          <v-icon dark>mdi-calendar-arrow-right</v-icon>-->
<!--          Schedule-->
<!--        </v-btn>-->
<!--      </div>-->
<!--      <div class="pl-0">-->
<!--        <v-btn-->
<!--            v-if="checkReadPermission($modules.facility.approval.slug)"-->
<!--            class="elevation-0 no-border-radius"-->
<!--            style="height: 40px; border: 1px solid #dcdcdc !important"-->
<!--            color="white"-->
<!--        >-->
<!--          <v-icon dark>mdi-check-decagram-outline</v-icon>-->
<!--          Approvals-->
<!--        </v-btn>-->
<!--      </div>-->
<!--      <div class="pl-0">-->
<!--        <v-btn-->
<!--            v-if="checkReadPermission($modules.schedule.configuration.slug)"-->
<!--            :disabled="!venueService.venue_service_id"-->
<!--            @click="goToConfiguration"-->
<!--            class="elevation-0 no-left-border"-->
<!--            style="height: 40px; border: 1px solid #dcdcdc !important"-->
<!--            color="white"-->
<!--        >-->
<!--          <v-icon dark>mdi-cog-outline</v-icon>-->
<!--          Configuration-->
<!--        </v-btn>-->
<!--      </div>-->
<v-spacer></v-spacer>
    </v-row>
    <div class="md-card md-theme-default mt-8 shadow rounded-5 pb-4 ">
      <div class="md-card-content md-card-table px-2">
        <div>
          <div class="d-flex justify-space-between align-center">
            <SvgIcon class="text-2xl font-semibold" text="Pending Bookings" >
            </SvgIcon>

            <div class="d-flex gap-x-5">

              <v-btn
                  class="export-button mt-2"
                  elevation="0"
                  height="40"
                  v-if="checkWritePermission($modules.facility.approval.slug) && this.selectedBookings.length > 0"
                  @click="bulkApprove"
              >
                <SvgIcon text="Approve selected" >
                  <template v-slot:icon>
                    <AcceptMultipleIcon/>
                  </template>
                </SvgIcon>
              </v-btn>
              <v-btn
                  class="export-button mt-2"
                  elevation="0"
                  height="40"
                  v-if="checkDeletePermission($modules.facility.approval.slug) && this.selectedBookings.length > 0"
                  @click="bulkReject"
              >
                <SvgIcon text="Reject selected" >
                  <template v-slot:icon>
                    <RejectMultipleIcon/>
                  </template>
                </SvgIcon>
              </v-btn>
              <v-btn
                  class="export-button mt-2"
                  elevation="0"
                  height="40"
                  v-if="checkWritePermission($modules.facility.approval.slug) && this.selectedBookings.length == 0 && bookingsList.length > 0"
                  @click="bulkApproveAll"
              >
                <SvgIcon text="Approve All" >
                  <template v-slot:icon>
                    <AcceptAllIcon/>
                  </template>
                </SvgIcon>
              </v-btn>
              <v-btn
                  class="export-button mt-2"
                  elevation="0"
                  height="40"
                  v-if="checkDeletePermission($modules.facility.approval.slug) && this.selectedBookings.length == 0 && bookingsList.length > 0"
                  @click="bulkRejectAllConfirmation"
              >
                <SvgIcon text="Reject All" >
                  <template v-slot:icon>
                    <RejectAllIcon/>
                  </template>
                </SvgIcon>
              </v-btn>


              <v-select
                  v-model="status_id"
                  :items="statusItems"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0  mt-2"
                  hide-details
                  item-text="name"
                  item-value="value"
                  outlined
                  label="Status"
                  @change="getFilterData"
                  style="max-width: 150px"
                  placeholder="Per Page"
                  dense
              ></v-select>

              <v-select
                  v-if="$store.getters.venueInfo.enable_cid"
                  v-model="pending_with"
                  :items="partyItems"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0  mt-2"
                  hide-details
                  item-text="name"
                  item-value="value"
                  outlined
                  label="With"
                  @change="getFilterData"
                  style="max-width: 150px"
                  placeholder="Per Page"
                  dense
              ></v-select>

              <v-select
                  v-model="perPage"
                  :items="[5,10, 15, 25, 50,100,200]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0  mt-2"
                  hide-details
                  outlined
                  @change="getFilterData"
                  style="max-width: 120px"
                  placeholder="Per Page"
                  dense
              ></v-select>
<!--              <v-btn-->
<!--                  class="export-button mt-2"-->
<!--                  elevation="0"-->
<!--                  height="40"-->
<!--                  v-if="-->
<!--                         checkExportPermission(-->
<!--                            $modules.memberships.management.slug-->
<!--                          )-->
<!--                      "-->
<!--                  @click="exportData"-->
<!--              >-->
<!--                <SvgIcon text="Export" >-->
<!--                  <template v-slot:icon>-->
<!--                    <ExportIcon/>-->
<!--                  </template>-->
<!--                </SvgIcon>-->
<!--              </v-btn>-->
            </div>
          </div>
          <div class="table-responsive mt-4" v-if="bookingsList.length > 0">
            <table class="table border-collapse ">
              <thead >
              <tr class="opacity-70 tr-neon tr-rounded ">
                <th>
                  <div class="">Select</div>
                </th><th>
                  <div class="">Timestamp</div>
                </th>
                <th>
                  <div class="">Due Date</div>
                </th>
                <th>
                  <div class="">Due Time</div>
                </th>
                <th>
                  <div class="">Facility</div>
                </th>
                <th>
                  <div class="">Name</div>
                </th>
                <th>
                  Email
                </th>
                <th>
                  Mobile
                </th>
                <th>
                  Nationality
                </th>
                <th>
                  Gender
                </th>
                <th>
                  Age
                </th>
                <th>
                  Status
                </th>
                <th v-if="$store.getters.venueInfo.enable_cid">
                  With
                </th>
                <th v-if="checkWritePermission($modules.facility.approval.slug) || checkDeletePermission($modules.facility.approval.slug)">
                  Actions
                </th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="booking in bookingsList"
                  :key="booking.id"
              >

                <td>
                  <input
                      :disabled="booking.is_booking_approved != 0"
                      type="checkbox"
                      :value="booking"
                      :checked="isSelected(booking)"
                      @change="toggleBooking(booking)"
                  />
                </td>

                <td >
                  <div >
                    {{ booking.created_at | timeStamp }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ booking.date | dateformat }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ booking.start_time | timeFormat }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ booking.facility_name }}
                  </div>
                </td>
                <td>
                  <div >
                    <span class="text_ellipse text-neon font-bold  pointer "  @click="showUserModel(booking.customer_id)">
                    {{ booking.name }}

                    </span>
                  </div>
                </td>
                <td >
                  <div >
                    {{ booking.email }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ booking.mobile }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ booking.nationality }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ booking.gender }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ booking.age }}
                  </div>
                </td>
                <td >
                  <div v-if="booking.is_booking_approved == 2 || booking.is_cid_approved == 2">
                    Rejected
                  </div>
                  <div v-else-if="booking.is_booking_approved == 0 || booking.is_cid_approved == 0">
                    Pending
                  </div>
                  <div v-else-if="booking.is_booking_approved == 1 && booking.is_cid_approved == 1">
                    Approved
                  </div>
                </td>
                <td v-if="$store.getters.venueInfo.enable_cid">
                  <div v-if="booking.is_booking_approved == 0 && (booking.is_cid_approved == 0 || !booking.is_cid_approved)">
                    {{ $store.getters.venueInfo.name }}
                  </div>
                  <div v-else-if="booking.is_booking_approved == 1 && (booking.is_cid_approved == 0 || !booking.is_cid_approved)">
                    CID
                  </div>
                  <div v-else-if="booking.is_booking_approved == 2">
                    {{ $store.getters.venueInfo.name }}
                  </div>
                  <div v-else-if="booking.is_cid_approved == 2">
                    CID
                  </div>
                  <div v-else-if="booking.is_cid_approved == 1">
                    All
                  </div>
                </td>
                <td v-if="checkWritePermission($modules.facility.approval.slug) || checkDeletePermission($modules.facility.approval.slug)">
                  <v-icon
                      v-if="checkWritePermission($modules.facility.approval.slug) && booking.is_booking_approved == 0"
                      @click="approveBooking(booking.id)"
                      class="pl-2"
                      color="#66c8c8"
                  >mdi-check</v-icon
                  >
                  <v-icon
                      v-if="checkDeletePermission($modules.facility.approval.slug) && booking.is_booking_approved == 0"
                      @click="confirmReject(booking.order_id)"
                      class="pl-2"
                      color="#961a04"
                  >mdi-close</v-icon
                  >
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p>No pending records for approval</p>
          </div>
        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="getFilterData"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>
    <v-dialog
        v-model="showRejectionModal"
        width="400px"
        scrollable
        @input="closeRejectionModal"
    >
      <v-card>
        <v-card-title class="headline"
        >Please add reason for rejection
        </v-card-title
        >
        <v-card-text class="pa-8">
          <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="submitRejectionReason"
              lazy-validation
          >
            <v-select
                hide-details="auto"
                v-model="rejectionReasonType"
                :items="[
                'Conflicting Schedule',
                'Unavailability of Tour Guide',
                'Weather Conditions',
                'Health and Safety Concerns',
                'Private Event Conflicts',
                'Resource Constraints',
                'Cancellation Requested by Customer',
                'Other (Please Specify)',
              ]"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Rejection Reason is required']"
                class="q-autocomplete shadow-0 mb-2"
                dense
                validate-on-blur
            >
            </v-select>
            <v-textarea
                name="rejection_reason"
                label="Rejection reason"
                v-model="rejectionReason"
                v-if="rejectionReasonType === 'Other (Please Specify)'"
                outlined
                background-color="#fff"
                :rules="[(v) => !!v || 'Rejection reason is required']"
            ></v-textarea>
            <div class="d-flex justify-end">
              <v-btn class="red-color" dark text type="submit"> Reject</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <customer-model v-bind="userModel" @close="userModel.userID = null" />
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
// import ExportIcon from "@/assets/images/misc/export-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import {mapGetters} from "vuex";
import CustomerModel from "@/views/Clients/Customer/CustomerModel.vue";
import ScheduleTabs from "@/components/Schedule/ScheduleTabs.vue";
// import ExportIcon from "@/assets/images/misc/export-icon.svg";
import AcceptAllIcon from "@/assets/images/misc/ApproveAll.svg"
import RejectAllIcon from "@/assets/images/misc/RejectAll.svg"
import RejectMultipleIcon from "@/assets/images/misc/RejectMultiple.svg"
import AcceptMultipleIcon from "@/assets/images/misc/ApproveMultiple.svg"
// import BackButton from "@/components/Common/BackButton.vue";

export default {
  name: "ScheduleApprovals",
  components: { ScheduleTabs, CustomerModel, SvgIcon, AcceptAllIcon, RejectAllIcon, RejectMultipleIcon, AcceptMultipleIcon},
  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
    venueServices() {
      return this.$store.getters.getSportsService.filter(
          (service) => service.name != "POS"
      );
    },
  },
  watch: {
    page() {
      this.getMembersList();
    },
  },
  data() {
    return {
      status_id:0,
      pending_with:0,
      statusItems:[
        {
          'name': 'Pending',
          'value': 0
        },
        {
          'name': 'Approved',
          'value': 1
        },
        {
          'name': 'Rejected',
          'value': 2
        },
      ],
      partyItems:[
        {
          'name': this.$store.getters.venueInfo.name ,
          'value': 0
        },
        {
          'name': 'CID',
          'value': 1
        },
      ],
      valid:false,
      perPage: 10,
      btnShow: false,
      bookingsList: [],
      isLoading: false,
      page: 1,
      searchParams: { memberships: [] },
      totalPages: 1,
      userModel: { userID: null, type: "details" },
      showRejectionModal: false,
      rejectionReason: "",
      rejectionReasonType: null,
      venueService: {},
      reject_id:null,
      selectedBookings: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },

  mounted() {
    this.getMembersList();
    if (this.$store.getters.getVenueServices.status == false) {
      this.showLoader("Loading");
      this.$store.dispatch("loadVenueServices").then(() => {
        this.$nextTick(() => {
          if (this.$store.getters.getSportsService.length) {
            this.venueService = this.$store.getters.getSportsService.filter(
                (service) => service.name != "POS"
            )[0];
          }
          this.hideLoader();
        });
      });
    } else {
      if (this.$store.getters.getSportsService.length) {
        this.venueService = this.$store.getters.getSportsService.filter(
            (service) => service.name != "POS"
        )[0];
        this.hideLoader();
      }
    }
  },
  methods:{
    confirmActions(data) {
      if (data.type == "reject_multiple") {
        this.bulkHandle(data.id);
      }
      if (data.type == "reject_all") {
        this.bulkRejectAll();
      }
      this.confirmModel.id = null;
    },
    bulkHandle(data){
      this.showLoader("Loading");
      this.$http
          .post(
              `venues/facilities/bookings/pending-bookings/multiple`,data
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Bookings successfully updated.");
              this.getMembersList();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    bulkApprove(){
      let data = {
        toggle_status:1,
        selected_bookings:this.selectedBookings
      }
      this.bulkHandle(data);
    },
    bulkReject(){
      let data = {
        toggle_status:0,
        selected_bookings:this.selectedBookings
      }

      this.confirmModel = {
        id: data,
        title: "Do you want to reject the selected bookings?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "reject_multiple",
      };

    },
    bulkRejectAllConfirmation(){
      this.confirmModel = {
        id: 0,
        title: "Do you want to reject all bookings?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "reject_all",
      };

    },
    bulkApproveAll(){
      this.showLoader("Loading");
      this.$http
          .post(
              `venues/facilities/bookings/pending-bookings/all`,{
                toggle_status:1
              }
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("All Bookings successfully approved.");
              this.getMembersList();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    bulkRejectAll(){
      this.showLoader("Loading");
      this.$http
          .post(
              `venues/facilities/bookings/pending-bookings/all`,{
                toggle_status:0
              }
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("All Bookings successfully rejected.");
              this.getMembersList();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    toggleBooking(booking) {
      const index = this.selectedBookings.findIndex(
          (selected) => selected.id === booking.id
      );

      if (index > -1) {
        // If already selected, remove it
        this.selectedBookings.splice(index, 1);
      } else {
        // If not selected, add it
        this.selectedBookings.push({
          id: booking.id,
          order_id: booking.order_id
        });
      }
    },
    isSelected(booking) {
      // Check if the booking is already selected
      return this.selectedBookings.some(
          (selected) => selected.id === booking.id
      );
    },
    goToConfiguration() {
      if (this.venueService.venue_service_id) {
        this.$router.push({
          name: "ScheduleConfiguration",
          params: { data: btoa(this.venueService.venue_service_id) },
        });
      } else {
        this.errorChecker("Please select service");
      }
    },
    gotoSchedule(){
      this.$router.push({name: "Schedule"}, () => {
      });
    },
    getFilterData() {
      this.page = 1;
      this.getMembersList();
    },
    confirmReject(id) {
      this.reject_id = id;
      this.showRejectionModal = true;
    },
    closeRejectionModal() {
      this.showRejectionModal = false;
      this.rejectionReason = null;
      this.rejectionReasonType = null;
      this.reject_id = null;
    },
    getMembersList(){
      console.log(this.$store.getters.venueInfo);
      this.selectedBookings = [];
      let url = `&booking_approval_status=${this.status_id}&pending_with=${this.pending_with}`;
      this.$http
          .get(
              `venues/facilities/bookings/pending-bookings?page=${this.page}&per_page=${
                  this.perPage != null ? this.perPage : 10
              }${url}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.bookingsList = response.data.data;
              this.totalPages = response.data.total_pages;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    approveBooking(id) {
      this.showLoader("Loading");
      this.$http
          .get(
              `venues/facilities/bookings/booking-status/${id}/approve`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Booking successfully Approved.");
              this.getMembersList();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    submitRejectionReason() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Loading");
      this.$http
          .delete(`venues/orders/${this.reject_id}`, {
            data: {
              rejection_reason: this.rejectionReason,
              rejection_reason_type: this.rejectionReasonType,
              type: "rejection",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Booking successfully rejected.");
              this.closeRejectionModal()
              this.getMembersList();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
  }
}
</script>
<style scoped>
tbody {
  * {
    font-size:1rem !important;
  }
}
</style>